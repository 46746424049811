import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="container py-5">
      <h1 className="font-weight-bold mb-4">Privacy Policy</h1>
      <p>
        This Privacy Policy applies between you, the User of this Website and
        Energi Impact Core Limited, the owner and provider of this Website.
        Energi Impact Core Limited takes the privacy of your information very
        seriously. This Privacy Policy applies to our use of any and all Data
        collected by us or provided by you in relation to your use of the
        Website.
      </p>
      <p>
        <strong>Please read this Privacy Policy carefully</strong>.
      </p>
      <h2 className="h4 font-weight-bold my-4">
        Definitions and interpretation
      </h2>
      <ol type="1">
        <li>In this Privacy Policy, the following definitions are used: </li>
      </ol>
      <table className="table">
        <tbody>
          <tr>
            <td className="p-2">
              <strong>Data</strong>
            </td>
            <td className="p-2">
              collectively all information that you submit to Energi Impact Core
              Limited via the Website. This definition incorporates, where
              applicable, the definitions provided in the Data Protection Laws;
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>Cookies</strong>
            </td>
            <td className="p-2">
              a small text file placed on your computer by this Website when you
              visit certain parts of the Website and/or when you use certain
              features of the Website. Details of the cookies used by this
              Website are set out in the clause below (<strong>Cookies</strong>
              );
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>Data Protection Laws</strong>
            </td>
            <td className="p-2">
              any applicable law relating to the processing of personal Data,
              including but not limited to the Directive 96/46/EC (Data
              Protection Directive) or the GDPR, and any national implementing
              laws, regulations and secondary legislation, for as long as the
              GDPR is effective in the UK;
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>GDPR</strong>
            </td>
            <td className="p-2">
              the General Data Protection Regulation (EU) 2016/679;
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>Energi Impact Core Limited,</strong> or{' '}
              <strong>us</strong>
            </td>
            <td className="p-2">
              Energi Impact Core Limited, a company incorporated in British
              Virgin Islands with registered number 1998022 whose registered
              office is at OMC Chambers, Wickhams Cay 1, Road Town, Tortola,
              VG1110;
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>UK and EU Cookie Law</strong>
            </td>
            <td className="p-2">
              the Privacy and Electronic Communications (EC Directive)
              Regulations 2003 as amended by the Privacy and Electronic
              Communications (EC Directive) (Amendment) Regulations 2011;
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>User</strong> or <strong>you</strong>
            </td>
            <td className="p-2">
              any third party that accesses the Website and is not either (i)
              employed by Energi Impact Core Limited and acting in the course of
              their employment or (ii) engaged as a consultant or otherwise
              providing services to Energi Impact Core Limited and accessing the
              Website in connection with the provision of such services; and
            </td>
          </tr>
          <tr>
            <td className="p-2">
              <strong>Website</strong>
            </td>
            <td className="p-2">
              the website that you are currently using, https://www.Energi
              Impact.world/, and any sub-domains of this site unless expressly
              excluded by their own terms and conditions.
            </td>
          </tr>
        </tbody>
      </table>

      <ol type="1">
        <li>
          In this Privacy Policy, unless the context requires a different
          interpretation:
          <ol type="a">
            <li>the singular includes the plural and vice versa;</li>
            <li>
              references to sub-clauses, clauses, schedules or appendices are to
              sub-clauses, clauses, schedules or appendices of this Privacy
              Policy;
            </li>
            <li>
              a reference to a person includes firms, companies, government
              entities, trusts and partnerships;
            </li>
            <li>
              “including” is understood to mean “including without limitation”;
            </li>
            <li>
              reference to any statutory provision includes any modification or
              amendment of it;
            </li>
            <li>
              the headings and sub-headings do not form part of this Privacy
              Policy.
            </li>
          </ol>
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Scope of this Privacy Policy</h2>
      <ol type="1">
        <li>
          This Privacy Policy applies only to the actions of Energi Impact Core
          Limited and Users with respect to this Website. It does not extend to
          any websites that can be accessed from this Website including, but not
          limited to, any links we may provide to social media websites.
        </li>
        <li>
          For purposes of the applicable Data Protection Laws, Energi Impact
          Core Limited is the “data controller”. This means that Energi Impact
          Core Limited determines the purposes for which, and the manner in
          which, your Data is processed.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Data collected</h2>
      <ol type="1">
        <li>
          We may collect the following Data, which includes personal Data, from
          you:
          <ol type="a">
            <li>name;</li>
            <li>date of birth;</li>
            <li>gender;</li>
            <li>
              contact Information such as email addresses and telephone numbers;
            </li>
            <li>
              demographic information such as postcode, preferences and
              interests;
            </li>
            <li>IP address (automatically collected);</li>
            <li>web browser type and version (automatically collected);</li>
            <li>operating system (automatically collected);</li>
            <li>
              a list of URLs starting with a referring site, your activity on
              this Website, and the site you exit to (automatically collected);
            </li>
            <li>in each case, in accordance with this Privacy Policy.</li>
          </ol>
        </li>
      </ol>

      <h2 className="h4 font-weight-bold my-4">How we collect Data</h2>

      <ol type="1">
        <li>
          We collect Data in the following ways:
          <ol type="a">
            <li>data is given to us by you; and</li>
            <li>data is collected automatically.</li>
          </ol>
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">
        Data that is given to us by you
      </h2>
      <ol type="1">
        <li>
          Energi Impact Core Limited will collect your Data in a number of ways,
          for example:
          <ol type="a">
            <li>
              when you contact us through the Website, by telephone, post,
              e-mail or through any other means;
            </li>
            <li>
              when you register with us and set up an account to receive our
              products/services;
            </li>
            <li>when you elect to receive marketing communications from us;</li>
          </ol>
        </li>
      </ol>
      <p>in each case, in accordance with this Privacy Policy.</p>
      <h2 className="h4 font-weight-bold my-4">
        Data that is collected automatically
      </h2>
      <ol type="1">
        <li>
          To the extent that you access the Website, we will collect your Data
          automatically, for example:
          <ol type="a">
            <li>
              we automatically collect some information about your visit to the
              Website. This information helps us to make improvements to Website
              content and navigation, and includes your IP address, the date,
              times and frequency with which you access the Website and the way
              you use and interact with its content.
            </li>
            <li>
              we will collect your Data automatically via cookies, in line with
              the cookie settings on your browser. For more information about
              cookies, and how we use them on the Website, see the section
              below, headed “Cookies”.
            </li>
          </ol>
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Our use of Data</h2>
      <ol type="1">
        <li>
          Any or all of the above Data may be required by us from time to time
          in order to provide you with the best possible service and experience
          when using our Website. Specifically, Data may be used by us for the
          following reasons:
          <ol type="a">
            <li>internal record keeping;</li>
            <li>improvement of our products / services;</li>
            <li>
              transmission by email of marketing materials that may be of
              interest to you;
            </li>
            <li>
              contact for market research purposes which may be done using
              email, telephone, fax or mail. Such information may be used to
              customise or update the Website;
            </li>
          </ol>
        </li>
      </ol>
      <p>in each case, in accordance with this Privacy Policy.</p>
      <ol type="1">
        <li>
          We may use your Data for the above purposes if we deem it necessary to
          do so for our legitimate interests. If you are not satisfied with
          this, you have the right to object in certain circumstances (see the
          section headed “Your rights” below).
        </li>
        <li>
          For the delivery of direct marketing to you via e-mail, we’ll need
          your consent, whether via an opt-in or soft-opt-in:
          <ol type="a">
            <li>
              soft opt-in consent is a specific type of consent which applies
              when you have previously engaged with us (for example, you contact
              us to ask us for more details about a particular product/service,
              and we are marketing similar products/services). Under “soft
              opt-in” consent, we will take your consent as given unless you
              opt-out.
            </li>
            <li>
              for other types of e-marketing, we are required to obtain your
              explicit consent; that is, you need to take positive and
              affirmative action when consenting by, for example, checking a
              tick box that we’ll provide.
            </li>
            <li>
              if you are not satisfied about our approach to marketing, you have
              the right to withdraw consent at any time. To find out how to
              withdraw your consent, see the section headed “Your rights” below.
            </li>
          </ol>
        </li>
        <li>
          When you register with us and set up an account to receive our
          services, the legal basis for this processing is the performance of a
          contract between you and us and/or taking steps, at your request, to
          enter into such a contract.
        </li>
        <li>
          We may use your Data to show you Energi Impact Core Limited adverts
          and other content on other websites. If you do not want us to use your
          data to show you Energi Impact Core Limited adverts and other content
          on other websites, please turn off the relevant cookies (please refer
          to the section headed “Cookies” below).
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Who we share Data with</h2>
      <ol type="1">
        <li>
          We may share your Data with the following groups of people for the
          following reasons:
          <ol type="a">
            <li>
              our employees, agents and/or professional advisors – to ensure
              candidates for Earndrop campaigns are eligible;
            </li>
          </ol>
        </li>
      </ol>
      <p>in each case, in accordance with this Privacy Policy.</p>
      <h2 className="h4 font-weight-bold my-4">Keeping Data secure</h2>
      <ol type="1">
        <li>
          We will use technical and organisational measures to safeguard your
          Data, for example:
          <ol type="a">
            <li>
              access to your account is controlled by a password and a user name
              that is unique to you.
            </li>
            <li>we store your Data on secure servers.</li>
          </ol>
        </li>
        <li>
          Technical and organisational measures include measures to deal with
          any suspected data breach. If you suspect any misuse or loss or
          unauthorised access to your Data, please let us know immediately by
          contacting us via this e-mail address: contact@energiimpact.team
        </li>
        <li>
          If you want detailed information from Get Safe Online on how to
          protect your information and your computers and devices against fraud,
          identity theft, viruses and many other online problems, please visit
          www.getsafeonline.org. Get Safe Online is supported by HM Government
          and leading businesses.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Data retention</h2>
      <ol type="1">
        <li>
          Unless a longer retention period is required or permitted by law, we
          will only hold your Data on our systems for the period necessary to
          fulfil the purposes outlined in this Privacy Policy or until you
          request that the Data be deleted.
        </li>
        <li>
          Even if we delete your Data, it may persist on backup or archival
          media for legal, tax or regulatory purposes.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Your rights</h2>
      <ol type="1">
        <li>
          You have the following rights in relation to your Data:
          <ol type="a">
            <li>
              <strong>Right to access</strong> – the right to request (i) copies
              of the information we hold about you at any time, or (ii) that we
              modify, update or delete such information. If we provide you with
              access to the information we hold about you, we will not charge
              you for this, unless your request is “manifestly unfounded or
              excessive.” Where we are legally permitted to do so, we may refuse
              your request. If we refuse your request, we will tell you the
              reasons why.
            </li>
            <li>
              <strong>Right to correct</strong> – the right to have your Data
              rectified if it is inaccurate or incomplete.
            </li>
            <li>
              <strong>Right to erase</strong> – the right to request that we
              delete or remove your Data from our systems.
            </li>
            <li>
              <strong>Right to restrict our use of your Data</strong> – the
              right to “block” us from using your Data or limit the way in which
              we can use it.
            </li>
            <li>
              <strong>Right to data portability</strong> – the right to request
              that we move, copy or transfer your Data.
            </li>
            <li>
              <strong>Right to object</strong> – the right to object to our use
              of your Data including where we use it for our legitimate
              interests.
            </li>
          </ol>
        </li>
        <li>
          To make enquiries, exercise any of your rights set out above, or
          withdraw your consent to the processing of your Data (where consent is
          our legal basis for processing your Data), please contact us via this
          e-mail address: contact@energiimpact.team
        </li>
        <li>
          If you are not satisfied with the way a complaint you make in relation
          to your Data is handled by us, you may be able to refer your complaint
          to the relevant data protection authority. For the UK, this is the
          Information Commissioner’s Office (ICO). The ICO’s contact details can
          be found on their website at https://ico.org.uk/.
        </li>
        <li>
          It is important that the Data we hold about you is accurate and
          current. Please keep us informed if your Data changes during the
          period for which we hold it.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">
        Transfers outside the European Economic Area
      </h2>
      <ol type="1">
        <li>
          Data which we collect from you may be stored and processed in and
          transferred to countries outside of the European Economic Area (EEA).
          For example, this could occur if our servers are located in a country
          outside the EEA or one of our service providers is situated in a
          country outside the EEA.
        </li>
        <li>
          We will only transfer Data outside the EEA where it is compliant with
          data protection legislation and the means of transfer provides
          adequate safeguards in relation to your data, eg by way of data
          transfer agreement, incorporating the current standard contractual
          clauses adopted by the European Commission, or by signing up to the
          EU-US Privacy Shield Framework, in the event that the organisation in
          receipt of the Data is based in the United States of America.
        </li>
        <li>
          To ensure that your Data receives an adequate level of protection, we
          have put in place appropriate safeguards and procedures with the third
          parties we share your Data with. This ensures your Data is treated by
          those third parties in a way that is consistent with the Data
          Protection Laws.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Links to other websites</h2>
      <ol type="1">
        <li>
          This Website may, from time to time, provide links to other websites.
          We have no control over such websites and are not responsible for the
          content of these websites. This Privacy Policy does not extend to your
          use of such websites. You are advised to read the Privacy Policy or
          statement of other websites prior to using them.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">
        Changes of business ownership and control
      </h2>
      <ol type="1">
        <li>
          Energi Impact Core Limited may, from time to time, expand or reduce
          our business and this may involve the sale and/or the transfer of
          control of all or part of Energi Impact Core Limited. Data provided by
          Users will, where it is relevant to any part of our business so
          transferred, be transferred along with that part and the new owner or
          newly controlling party will, under the terms of this Privacy Policy,
          be permitted to use the Data for the purposes for which it was
          originally supplied to us.
        </li>
        <li>
          We may also disclose Data to a prospective purchaser of our business
          or any part of it.
        </li>
        <li>
          In the above instances, we will take steps with the aim of ensuring
          your privacy is protected.
        </li>
      </ol>

      <h2 className="h4 font-weight-bold my-4">Cookie Policy</h2>
      <p>
        Cookies are alphanumeric identifiers that we transfer to your computer's
        hard drive through your Web browser to enable our systems to recognize
        your browser and tell us how and when pages in our website are visited
        and by how many people.
      </p>
      <ol type="1">
        <li>
          This Website may place and access certain Cookies on your computer.
          Energi Impact Core Limited uses Cookies to improve your experience of
          using the Website. Energi Impact Core Limited has carefully chosen
          these Cookies and has taken steps to ensure that your privacy is
          protected and respected at all times.
        </li>
        <li>
          All Cookies used by this Website are used in accordance with current
          UK and EU Cookie Law.
        </li>
        <li>
          Before the Website places Cookies on your computer, you will be
          presented with a message bar requesting your consent to set those
          Cookies. By giving your consent to the placing of Cookies, you are
          enabling Energi Impact Core Limited to provide a better experience and
          service to you. You may, if you wish, deny consent to the placing of
          Cookies; however certain features of the Website may not function
          fully or as intended.
        </li>
        <li>This Website may place the following Cookies:</li>
      </ol>
      <table className="table">
        <tbody>
          <tr>
            <td className="p-2">
              <strong>Type of Cookie</strong>
            </td>
            <td className="p-2">
              <strong>Purpose</strong>
            </td>
          </tr>
          <tr>
            <td className="p-2">Strictly necessary cookies</td>
            <td className="p-2">
              These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to log
              into secure areas of our website, use a shopping cart or make use
              of e-billing services.
            </td>
          </tr>
          <tr>
            <td className="p-2">Analytical/performance cookies</td>
            <td className="p-2">
              They allow us to recognise and count the number of visitors and to
              see how visitors move around our website when they are using it.
              This helps us to improve the way our website works, for example,
              by ensuring that users are finding what they are looking for
              easily.
            </td>
          </tr>
          <tr>
            <td className="p-2">Functionality cookies</td>
            <td className="p-2">
              These are used to recognise you when you return to our website.
              This enables us to personalise our content for you, greet you by
              name and remember your preferences (for example, your choice of
              language or region).
            </td>
          </tr>
          <tr>
            <td className="p-2">Targeting cookies</td>
            <td className="p-2">
              These cookies record your visit to our website, the pages you have
              visited and the links you have followed. We will use this
              information to make our website and the advertising displayed on
              it more relevant to your interests. We may also share this
              information with third parties for this purpose.
            </td>
          </tr>
        </tbody>
      </table>
      <ol type="1">
        <li>
          You can find a list of Cookies that we use in the Cookies Schedule.
        </li>
        <li>
          You can choose to enable or disable Cookies in your internet browser.
          By default, most internet browsers accept Cookies but this can be
          changed. For further details, please consult the help menu in your
          internet browser.
        </li>
        <li>
          You can choose to delete Cookies at any time; however you may lose any
          information that enables you to access the Website more quickly and
          efficiently including, but not limited to, personalisation settings.
        </li>
        <li>
          It is recommended that you ensure that your internet browser is
          up-to-date and that you consult the help and guidance provided by the
          developer of your internet browser if you are unsure about adjusting
          your privacy settings.
        </li>
        <li>
          For more information generally on cookies, including how to disable
          them, please refer to aboutcookies.org. You will also find details on
          how to delete cookies from your computer.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">General</h2>
      <ol type="1">
        <li>
          You may not transfer any of your rights under this Privacy Policy to
          any other person. We may transfer our rights under this Privacy Policy
          where we reasonably believe your rights will not be affected.
        </li>
        <li>
          If any court or competent authority finds that any provision of this
          Privacy Policy (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of this Privacy Policy will not be affected.
        </li>
        <li>
          Unless otherwise agreed, no delay, act or omission by a party in
          exercising any right or remedy will be deemed a waiver of that, or any
          other, right or remedy.
        </li>
        <li>
          This Agreement will be governed by and interpreted according to the
          law of England and Wales. All disputes arising under the Agreement
          will be subject to the exclusive jurisdiction of the English and Welsh
          courts.
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">EU Personal Data</h2>
      <p>
        If you are located in the EU, United Kingdom, Lichtenstein, Norway, or
        Iceland, you may have additional rights under the EU General Data
        Protection Regulation (the “GDPR”) related to your personal data.&nbsp;
        We will be the controller of your personal data processed in connection
        with the Site.
      </p>
      <p>
        {' '}
        You have the right to fully access all of your data.&nbsp; Without
        prejudice to any other administrative or judicial remedy, every user
        shall have the right to lodge a complaint with a supervisory authority,
        in particular in the Member State of his or her habitual residence,
        place of work or place of the alleged infringement if the user considers
        that the processing of personal data relating to him or her infringes
        this Regulation.{' '}
      </p>
      <p>
        You have the right to restrict or object to any of our data retention
        policies.&nbsp; You may request corrections to data.&nbsp; You have the
        right to delete your data from the site.&nbsp;{' '}
      </p>
      <p>
        Should you select the optional opportunity to receive promotional emails
        from us about current offers and incentives, we will also use your email
        for this purpose. If you have not selected this Opt-in option, you will
        not receive promotional emails. If you have opted to receive promotional
        emails, or if you receive transactional emails about your incomplete
        order, you may choose to remove (Opt-out) this feature at any time and
        the emails will be stopped.&nbsp;
      </p>
      <p>
        Your contact information may also be used should we ever need to contact
        you.&nbsp; The user has the right not to be subject to a decision based
        solely on automated processing, including profiling, which produces
        legal effects concerning him or her or similarly significantly affects
        him or her.&nbsp; Please forward any data inquiries to:
        contact@energiimpact.team{' '}
      </p>
      <h2 className="h4 font-weight-bold my-4">
        Changes to this Privacy Policy
      </h2>
      <ol type="1">
        <li>
          Energi Impact Core Limited reserves the right to change this Privacy
          Policy as we may deem necessary from time to time or as may be
          required by law. Any changes will be immediately posted on the Website
          and you are deemed to have accepted the terms of the Privacy Policy on
          your first use of the Website following the alterations.You may
          contact Energi Impact Core Limited by email at
          contact@energiimpact.team
        </li>
      </ol>
      <h2 className="h4 font-weight-bold my-4">Cookies</h2>
      <p>
        Below is a list of the cookies that we use. We have tried to ensure this
        is complete and up to date, but if you think that we have missed a
        cookie or there is any discrepancy, please let us know.
      </p>
      <p>Strictly necessary</p>
      <p>We use the following strictly necessary cookies:</p>
      <table className="table">
        <tbody>
          <tr>
            <td className="p-2">
              <strong>Description of Cookie</strong>
            </td>
            <td className="p-2">
              <strong>Purpose</strong>
            </td>
          </tr>
          <tr>
            <td className="p-2">CookieConsent</td>
            <td className="p-2">
              Stores the user’s cookie consent state for the current domain.
            </td>
          </tr>
        </tbody>
      </table>
      <p>Analytical/performance</p>
      <p>We use the following analytical/performance cookies:</p>
      <table className="table">
        <tbody>
          <tr>
            <td className="p-2">
              <strong>Description of Cookie</strong>
            </td>
            <td className="p-2">
              <strong>Purpose</strong>
            </td>
          </tr>
          <tr>
            <td className="p-2">_ga</td>
            <td className="p-2">
              Registers a unique ID that is used to generate statistical data on
              how the visitor uses the website.
            </td>
          </tr>
          <tr>
            <td className="p-2">_gid</td>
            <td className="p-2">
              Registers a unique ID that is used to generate statistical data on
              how the visitor uses the website.
            </td>
          </tr>
          <tr>
            <td className="p-2">pll_language</td>
            <td className="p-2">
              This cookie is used to determine the preferred language of the
              visitor and sets the language accordingly on the website, if
              possible.
            </td>
          </tr>
          <tr>
            <td className="p-2">_gat</td>
            <td className="p-2">
              Used by Google Analytics to throttle request rate.
            </td>
          </tr>
        </tbody>
      </table>
      <p className="mt-4">Functionality</p>
      <p>We use the following functionality cookies:</p>
      <table className="table">
        <tbody>
          <tr>
            <td className="p-2">
              <strong>Description of Cookie</strong>
            </td>
            <td className="p-2">
              <strong>Purpose</strong>
            </td>
          </tr>
          <tr>
            <td className="p-2">r/collect</td>
            <td className="p-2">
              This cookie is used to send data to Google Analytics about the
              visitor’s device and behavior. It tracks the visitor across
              devices and marketing channels.
            </td>
          </tr>
          <tr>
            <td className="p-2">test_cookie</td>
            <td className="p-2">
              Used to check if the user’s browser supports cookies.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-cast-installed</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-connected-devices</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-device-id</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-fast-check-period</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-session-app</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-session-app</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
          <tr>
            <td className="p-2">yt-remote-session-name</td>
            <td className="p-2">
              Stores the user’s video player preferences using embedded YouTube
              video.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)
export default PrivacyPolicyPage
